{
  "common": {
    "button": {
      "edit": "Muokkaa",
      "accept": "Hyväksy",
      "decline": "Hylkää",
      "ok": "Ok",
      "cancel": "Peruuta",
      "close": "Sulje",
      "saveAndClose": "Tallenna ja sulje",
      "signIn": "Kirjaudu sisään",
      "submit": "Submit",
      "forgotPassword": "Forgot password",
      "backToSignIn": "Back to Sign in",
      "startRegistration": "Aloita rekisteröinti",
      "create": "Create",
      "update": "Update",
      "save": "Save",
      "addNewUser": "Lisää uusi käyttäjä",
      "search": "Search",
      "delete": "Delete",
      "deleting": "Deleting",
      "back": "Back",
      "change": "Change",
      "verify": "Verify",
      "yes": "Yes",
      "no": "No",
      "exportToExcel": "Export to Excel"
    },
    "table": {
      "header": {
        "processed": "Processed",
        "actions": "Toiminnot",
        "address": "Osoite",
        "capacity": "Kapasiteetti (kWh/h)",
        "updated": "Updated",
        "company": "Yritys",
        "marketparty": "Marketparty",
        "created": "Luotu",
        "createdBy": "Luonut",
        "deliveryEndTime": "Toimitusaika",
        "deliveryStartTime": "Toimitusajan alkamisaika",
        "marketPartyName": "Market party name",
        "edit": "Muokkaa",
        "eicx": "EICX",
        "endTime": "End time",
        "gs1Retailer": "Vähittäismyyjä GS1",
        "gs1Dso": "DSO GS1",
        "id": "ID",
        "meteringSite": "Mittauspaikka",
        "connectionPoint": "Paikka",
        "product": "Tuote",
        "reservedFor": "Varattu",
        "shipper": "Lähettäjä",
        "startTime": "Start time",
        "state": "Valtio",
        "status": "Tila",
        "transfer": "Siirto",
        "vatId": "ALV-tunnus",
        "timePeriod": "Ajanjakso",
        "price": "Hinta",
        "type": "Tyyppi",
        "memberUntil": "Member Until",
        "balance": "Balance",
        "eic": "EIC",
        "startDate": "Aloitus pvm",
        "endDate": "Lopetus pvm",
        "startGasDay": "Alkaen kaasupvm",
        "endGasDay": "Päättyy kaasupvm",
        "balanceGroupOwner": "Tasevastaava",
        "imbalance": "Imbalance",
        "name": "Nimi",
        "street": "Katuosoite",
        "postalCode": "Postinumero",
        "city": "Kaupunki",
        "country": "Maa",
        "amount": "Määrä",
        "interConnectionPoint": "Yhteenliitäntäpiste",
        "user": "Käyttäjä",
        "phone": "Puhelin nro.",
        "email": "Sähköposti",
        "trader": "Kauppias",
        "producer": "Tuottaja",
        "enduser": "Loppukäyttäjä",
        "retailer": "Jälleenmyyjä",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO",
        "authority": "Viranomainen",
        "external": "Ulkoinen",
        "read": "luku",
        "write": "kirjoitus",
        "admin": "admin",
        "net": "Net",
        "domesticEntryExit": "Domestic entry / exit",
        "vtp": "VTP",
        "importsExports": "Imports / exports",
        "received": "Received",
        "pointDirection": "Point — Direction",
        "point": "Point",
        "gasDay": "Gas day",
        "sent": "Sent",
        "acknowledgedByTSO": "Acknowledged by TSO",
        "gasAmount": "Gas amount ({{unit}}/d)",
        "annual": "Annual",
        "quarterly": "Quarterly",
        "monthly": "Monthly",
        "daily": "Daily",
        "hourly": "Hourly",
        "transfers": "Transfers",
        "sum": "Sum",
        "counterparty": "Counterparty",
        "owner": "Owner",
        "bookedCapacity": "Booked capacity",
        "totalCapacity": "Total capacity (kWh)",
        "balanceGasPrice": "Price (€/MWh)",
        "overrun": "Overrun",
        "imatraEntry": "Imatra entry",
        "bcEntry": "BC entry",
        "bcExit": "BC exit",
        "vtpEntry": "VTP entry",
        "vtpExit": "VTP exit",
        "lngEntry": "LNG entry",
        "lngExit": "LNG exit",
        "lngHamina": "LNG Hamina",
        "lngInkoo": "LNG Inkoo",
        "lngHaminaEntry": "LNG Hamina entry",
        "lngHaminaExit": "LNG Hamina exit",
        "lngInkooEntry": "LNG Inkoo entry",
        "lngInkooExit": "LNG Inkoo exit",
        "entryTotal": "Entry total",
        "exitTotal": "Exit total",
        "total": "Total",
        "entry": "Entry",
        "exit": "Exit",
        "imatra": "Imatra",
        "bc": "BC",
        "biogas": "Biogas",
        "consumption": "Consumption",
        "acknow": "ACKNOW",
        "counterParty": "Counter party",
        "direction": "Direction",
        "info": "Info",
        "nomint": "NOMINT",
        "counterNomint": "Counter NOMINT",
        "nomres": "NOMRES",
        "hour": "Hour",
        "preliminary": "Preliminary",
        "historical": "Historical",
        "manual": "Manual",
        "estimatedAt": "Estimated at",
        "original": "Original",
        "value": "Value",
        "monthlyCorrected": "Monthly corrected",
        "yearlyCorrected": "Yearly corrected",
        "latest": "Latest",
        "monthlyDelta": "Monthly delta",
        "yearlyDelta": "Yearly delta",
        "volume": "Volume",
        "unit": "Unit",
        "marketParty": "Market party",
        "set": "Set",
        "required": "Required",
        "used": "Used",
        "remaining": "Remaining",
        "invoice": "Invoice",
        "analyser": "Analyser",
        "taxed": "Taxed",
        "emissionTrade": "Emission trade",
        "updatedAt": "Updated at",
        "ownerCode": "Code",
        "notes": "Merkinnät"
      }
    },
    "status": {
      "accept": "hyväksy",
      "decline": "lasku",
      "wait": "Odota",
      "accepted": "Hyväksytty",
      "pending": "Odottaa",
      "declined": "Hylätty",
      "notExists": "Not exists",
      "successfullyCreated": "successfully created",
      "successfullyUpdated": "successfully updated",
      "successfullyDeleted": "successfully deleted",
      "sending": "Lähetetään",
      "loading": "Loading"
    },
    "notifications": {
      "errorUpdating": "Error updating",
      "errorCreating": "Error creating",
      "errorFetching": "Error fetching",
      "errorDeleting": "Error deleting",
      "errorUpdatingTotalCapacities": "Error updating total capacities",
      "errorUpdatingPhysicalBalanceSummary": "Error updating physical balance summary",
      "errorUpdatingMeteringSiteConsumptions": "Error updating metering site consumptions",
      "errorUpdatingCapacityOverruns": "Error updating capacity overruns",
      "errorUpdatingCurrentUser": "Error updating current user",
      "errorUpdatingBookedCapacity": "Error updating booked capacity",
      "errorUpdatingAvailableCapacityImatra": "Error updating available capacity for Imatra",
      "errorUpdatingAvailableCapacityBC": "Error updating available capacity for Balticconnector",
      "errorUpdatingNominations": "Error updating nominations",
      "errorFetchingMarketPartyIndex": "Error fetching market party index",
      "errorFetchingMarketParties": "Error fetching market parties",
      "errorFetchingMeteringSites": "Error fetching metering sites",
      "errorFetchingMeteringPointAnalysers": "Error fetching metering point analysers",
      "errorFetchingDeliveryRelationships": "Error fetching delivery relationships",
      "errorFetchingCapacityReservations": "Error fetching capacity reservations",
      "errorFetchingCapacityTransfers": "Error fetching capacity transfers",
      "errorFetchingCapacities": "Error fetching capacities",
      "errorFetchingBalanceGroups": "Error fetching balance groups",
      "errorFetchingBalanceGroupStats": "Error fetching balance group stats",
      "errorFetchingBalanceGroupMembers": "Error fetching balance group members",
      "errorFetchingDeliveries": "Error fetching deliveries",
      "errorFetchingMyBalance": "Error fetching my balance stats",
      "errorFetchingNetworkStatus": "Error fetching transmission network status",
      "errorFetchingUsers": "Error fetching users",
      "errorFetchingNominations": "Virhe nominnoinin latauksessa",
      "errorFetchingNominationsSummary": "Error fetching nominations summary",
      "errorFetchingRelationships": "Error fetching market party relationships",
      "errorFetchingImbalanceEstimates": "Error fetching imbalance estimates",
      "errorFetchingInvoicedTaxes": "Error fetching invoiced taxes",
      "errorClosingConnection": "Error closing connection",
      "errorDeletingCapacityBooking": "Error while deleting capacity booking",
      "errorUpdatingActiveBalanceGroupId": {
        "title": "Virhe päivitettäessä aktiivista taseryhmää",
        "description": "Valitulla markkinaosapuolella ei ole aktiivista taseryhmää"
      },
      "errorFetchingBalancePrices": "Error fetching balance gas prices",
      "errorFetchingCapacityPrices": "Error fetching capacity prices",
      "errorFetchingInvoices": "Error fetching invoices",
      "errorFetchingCapacitySums": "Error fetching capacity sums",
      "errorFetchingDepositSummary": "Error fetching deposit summary",
      "errorFetchingDeposits": "Error fetching deposits",
      "errorSavingDeposit": "Error saving deposit",
      "errorFetchingOwnerships": "Error fetching metering point ownerships",
      "errorFetchingTaxDeclarations": "Error fetching tax declarations",
      "incorrectInput": "Check input data!",
      "failedToSave": "Failed to save",
      "failedToSend": "Failed to send",
      "savedSuccessfully": "Saved successfully",
      "deletedSuccessfully": "Deleted successfully",
      "cancelledSuccessfully": "Cancelled successfully",
      "errorEmailNotValid": "email must be a valid email",
      "cognitoNotAuthorizedException": "User password cannot be reset in the current state. Please contact an administrator.",
      "cognitoUserNotFoundException": "Username with the given email not found",
      "cognitoUserNotConfirmedException": "User has not been confirmed.",
      "cognitoLimitExceededException": "Attempt limit exceeded, please try after some time.",
      "cognitoCodeMismatchException": "Verification code was not correct.",
      "cognitoExpiredCodeException": "Verification code has expired.",
      "cognitoInvalidParameterException": "The given parameters were invalid.",
      "cognitoInvalidPasswordException": "The given password was invalid.",
      "cognitoPasswordTooShortException": "Password not long enough.",
      "cognitoPasswordMissingUppercaseCharsException": "The password must contain atleast one uppercase character.",
      "cognitoPasswordMissingNumericCharsException": "The password must contain atleast one numeric character.",
      "cognitoPasswordMustHaveSymbolsException": "The password must contain atleast one symbolic character."
    },
    "form": {
      "dateErrorMessage": "Date should be selected!",
      "emptyInputFieldErrorMessage": "Field can not be empty!",
      "passwordNotLongEnoughMessage": "Password needs to be atleast 8 characters long."
    },
    "label": {
      "currency": "€",
      "notAvailable": "N/A",
      "mwh": "MWh",
      "kwh": "kWh",
      "gwh": "GWh",
      "mwhPerHour": "MWh/h",
      "kwhPerHour": "kWh/h",
      "unit": "Unit",
      "resolution": "Resolution",
      "hour": "Hour",
      "day": "Day",
      "month": "Month",
      "monthOfCreation": "Month of creation",
      "timePeriod": "Time period",
      "point": "Point",
      "exitZone": "Exit zone",
      "imatra": "Imatra",
      "biogas": "Biogas",
      "cityGate": "City gate",
      "balticConnector": "BC",
      "vtp": "VTP",
      "lng": "LNG",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo",
      "shipper": "Retailer – Shipper",
      "pointDirection": "Point — Direction",
      "authorizeToNominate": "Authorize to nominate",
      "shipperGroup": "Shipper group",
      "status": "Status",
      "direction": "Direction",
      "entry": "Entry",
      "exit": "Exit",
      "counterParties": "Counter parties",
      "registeredBy": "Rekisteröinnin tekijä",
      "updatedAt": "Updated at",
      "show": "Show",
      "hide": "Hide",
      "Upcoming": "Upcoming",
      "Ongoing": "Ongoing",
      "Past": "Past",
      "all": "All",
      "inactiveMarketParties": "Inactive market parties",
      "stateFilter": "Filter by state",
      "statusFilter": "Filter by status"
    },
    "date": {
      "today": "Today",
      "tomorrow": "Tomorrow"
    },
    "dialog": {
      "close": "Are you sure you want to close?",
      "save": "Are you sure you want to save?"
    },
    "user": {
      "name": "Name",
      "email": "Email",
      "phone": "Phone"
    }
  },
  "app": {
    "title": {
      "companyName": "Test Company"
    },
    "menu": {
      "admin": "Admin",
      "adminReadOnly": "Read Only Admin",
      "frontPage": "Etusivu",
      "capacity": "Kapasiteetti",
      "gasEnergy": "Kaasuenergia",
      "balance": "Tase",
      "balanceGroups": "Taseryhmät",
      "myBalance": "My balance",
      "notifications": "Ilmoitukset",
      "deliveryRelationships": "Toimitussuhteet",
      "relationships": "Suhteet",
      "created": "Created",
      "received": "Received",
      "marketParties": "Markkinaosapuolet",
      "billing": "Laskutus",
      "apiDocs": "API Docs",
      "logOut": "Kirjaudu ulos",
      "capacityReservations": "Kapasiteetin varaukset",
      "capacityRightsTransfers": "Kapasiteettioikeuksien siirrot",
      "meteringPoints": "Mittauspisteet",
      "biogasEntryPoints": "Biokaasun tulopisteet",
      "cityGates": "City gates",
      "shippers": "Lähettäjät",
      "users": "Käyttäjät",
      "nominations": "Nominations",
      "retailers": "Retailers",
      "networkLevel": "Verkkotaso",
      "monitoring": "Monitorointi",
      "capacityAndNominations": "Capacity and nominations",
      "capacitySettings": "Capacity settings",
      "transmissionNetworkState": "Siirtoverkon tila",
      "meteringSites": "Metering sites",
      "selectMarketParty": "Select market party",
      "copyMarketPartyId": "Copy market party ID",
      "settings": "Asetukset",
      "deliveries": "Toimitukset",
      "biogasDeliveries": "Biokaasu toimitukset",
      "invoices": "Laskut",
      "deposits": "Vakuudet",
      "declareGasTax": "Kaasuverot"
    },
    "handbook": "Handbook",
    "termsOfUse": "Käyttöehdot",
    "feedback": "Anna palautetta",
    "survey": "Answer the customer satisfaction survey here"
  },
  "login": {
    "description": {
      "signInToPortal": "Kirjaudu sisään Suomen kaasusiirtoverkonhaltijan Portaaliin",
      "registerAsMarketParty": "Haluatko rekisteröityä markkinaosapuoleksi?"
    },
    "form": {
      "label": {
        "username": "Käyttäjätunnus",
        "password": "Salasana"
      }
    }
  },
  "forgotPassword": {
    "title": "Recover password",
    "description": {
      "sendVerificationCode": "Submit your email address and we'll send you a verification code to reset your password.",
      "sendNewPassword": "You can now define your new password with the received verification code.",
      "returnToLogin": "You can now go back to login page and sign in with your new password."
    },
    "form": {
      "label": {
        "email": "Email",
        "verificationCode": "Verification code",
        "newPassword": "New password:",
        "newPasswordAgain": "New password (again)"
      },
      "message": {
        "emailType": "Not valid e-mail!",
        "passwordsMatchRequired": "Passwords need to match!",
        "smsVerificationCode": "Verification Code sent via SMS",
        "emailVerificationCode": "Verification Code sent via email"
      }
    }
  },
  "register": {
    "title": {
      "registerForMarketParty": "REKISTERÖITYMINEN MARKKINAOSAPUOLEKSI"
    },
    "form": {
      "label": {
        "typeOfMarketParty": "Haluan rekisteröityä markkinoille",
        "shipper": "Shipper",
        "trader": "Trader",
        "biogasInjector": "Biokaasun verkkoonsyöttäjä",
        "transmissionNetworkEndUser": "Siirtoverkon loppukäyttäjä",
        "retailer": "Vähittäismyyjä",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO",
        "authority": "Authority",
        "external": "External",
        "firstName": "Etunimi",
        "lastName": "Sukunimi",
        "email": "Sähköposti",
        "phoneNumber": "Puhelinnumero",
        "companyName": "Yrityksen nimi",
        "vatNumber": "Y-tunnus",
        "streetAddress": "Katuosoite",
        "city": "Kaupunki",
        "postalCode": "Postinumero",
        "country": "Maa",
        "eicx": "EIC-X",
        "retailerGs1Gln": "GS1 GLN (Vähittäismyyjä)",
        "dsoGs1Gln": "GS1 GLN (DSO)",
        "dataConsent": "Hyväksyn, että siirtoverkonhaltija tarkistaa tässä lomakkeessa annetut tiedot"
      },
      "message": {
        "selectTypeOfMarketParty": "Valitse vähintään yksi valintaruutu!",
        "firstNameRequired": "Anna etunimesi!",
        "lastNameRequired": "Anna sukunimi!",
        "emailRequired": "Anna sähköpostiisi!",
        "emailType": "Ei kelvollinen sähköposti!",
        "phoneNumberRequired": "Syötä puhelinnumerosi!",
        "phoneNumberType": "Ei kelvollinen puhelinnumero!",
        "companyNameRequired": "Anna yrityksesi nimi!",
        "vatNumberRequired": "Syötä alv- tunnuksesi!",
        "streetAddressRequired": "Anna katuosoite!",
        "cityRequired": "Anna kaupunki!",
        "postalCodeRequired": "Anna postinumerosi!",
        "postalCodeType": "Ei voimassa oleva postinumero!",
        "countryRequired": "Anna maasi!",
        "eicxRequired": "Anna EIC-X!",
        "eicxType": "Ei voimassa EIC-X!",
        "gs1GlnRequired": "Syötä GS1 GLN!"
      },
      "extraInfo": {
        "line1": {
          "part1": "Jos sinulla ei ole vielä EIC-koodia, katso ",
          "link": "https://gasgrid.fi/en/registration-as-market-participant/",
          "linkText": "ohjeet",
          "part2": " sen hakemiseksi"
        },
        "line2": "Toimita myös allekirjoitetut puitesopimukset kahtena kirjallisena alkuperäisenä kappaleena osoitteeseen:",
        "address": {
          "company": "Gasgrid Finland Oy",
          "recipient": "Niina Heiskanen",
          "streetAddress": "Kiehuvantie 189,",
          "postalAddress": "45100 Kouvola",
          "country": ""
        },
        "line3": "Rekisteröityessäsi shipperin tai traderin rooliin, toimita lisäksi:",
        "line4": "1) Kaupparekisteriote (korkeintaan 3 kuukautta vanha) ja",
        "line5": "2) Viimeisin tilinpäätös (jos olemassa) sähköpostilla, ",
        "line6": {
          "part1": "Lisätietoa rekisteröitymisprosessista ",
          "link": "https://gasgrid.fi/en/gas-market/market-rules-and-recommendations/",
          "linkText": "Kaasumarkkinan menettelytapa- ja tiedonvaihto-ohjeesta",
          "part2": ""
        }
      }
    },
    "successNotification": {
      "title": "Kiitos rekisteröitymisestä",
      "bodyText": "Rekisteröitymislomake on lähetetty onnistuneesti ja saat pian sähköpostin, joka sisältää kirjautumistietosi. Hakemuksesi käsitellään ja saat ilmoituksen kun prosessi on valmis ja voit kirjautua portaaliin.",
      "button": "Takaisin kirjautumissivulle"
    }
  },
  "settings": {
    "title": "Settings",
    "userInformation": {
      "title": "User information",
      "change": {
        "title": "Change {{value}}",
        "label": "New {{value}}",
        "verificationCode": "Verification code",
        "info": {
          "success": "{{value}} changed succesfully!",
          "noChange": "{{value}} was unchanged"
        },
        "error": {
          "invalid": "The input value is invalid",
          "required": "Value is required"
        }
      }
    }
  },
  "capacity": {
    "title": {
      "capacityOverview": "Kapasiteetin yleiskatsaus",
      "capacityReservations": "kapasiteetin varaukset",
      "capacityRightsTransfer": "kapasiteettioikeuksien siirrot",
      "summary": "Summary ({{point}})",
      "overruns": "Capacity overruns ({{point}})"
    },
    "subtitle": {
      "capacityReservations": "Viimeisimmät kapasiteetin varaukset",
      "capacityRightsTransfer": "Viimeisimmät ilmoitukset kapasiteettioikeuksien siirroista",
      "summary": "Summary of your capacity bookings and transfers for {{point}} (values in {{unit}})",
      "overruns": "Capacity overruns for past seven days"
    },
    "button": {
      "newCapacityReservation": "Uusi kapasiteetin varaus",
      "newCapacityRightsTransfer": "Uusi kapasiteetin oikeuksien siirto"
    },
    "link": {
      "viewAllCapacityReservation": "Näytä kaikki kapasiteetin varaukset",
      "viewAllCapacityRightsTransfer": "Näytä kaikki kapasiteettioikeuksien siirrot"
    },
    "label": {
      "annual": "Vuotuinen",
      "quarterly": "Quarterly",
      "monthly": "Kuukausi",
      "daily": "Daily",
      "hourly": "Hourly",
      "startDate": "Start date",
      "endDate": "End date"
    },
    "point": {
      "exitZone": "Ottovyöhyke",
      "imatra": "Imatra",
      "biogas": "Biokaasu",
      "lng": "LNG",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo"
    }
  },
  "capacityReservation": {
    "title": {
      "yourCapacityReservations": "Kapasiteettivaraus",
      "capacityReservationsSummary": "Capacity bookings summary",
      "confirmDelete": "Haluatko varmasti peruuttaa tämän kapasiteettivarauksen?",
      "capacityReservation": "Kapasiteettivaraus",
      "products": "Tuotteet"
    },
    "form": {
      "title": {
        "newCapacityReservation": "Uusi kapasiteetin varaus",
        "chooseCapacityProducts": "Valitse kapasiteettituotteet"
      },
      "button": {
        "cancelBooking": "Cancel booking"
      },
      "label": {
        "point": "Paikka",
        "from": "alkaen",
        "totalPrice": "Kokonaissumma",
        "disclaimer": "Huomioithan",
        "remainingDeposit": "Remaining deposit",
        "reason": "Reason for cancellation (maximum 4000 characters)"
      },
      "disclaimer": "Tariffijakso on kalenterivuosikohtainen, jolloin osa varattavista tuotteista voi ulottua nykyisen tariffijakson ulkopuolelle. Tällöin portaalin antama hinta on suuntaa-antava.",
      "info": {
        "line1": "Erroneous capacity bookings can be cancelled within 60 minutes of the time when Gasgrid has received the capacity booking. Cancelling can be done by clicking \"Decline\" button in the Capacity Booking view for the booking in question. If capacity booking has already been accepted by Gasgrid and \"Decline\" button is no longer available, cancellation can be done within 60 minutes by sending e-mail to customerservice@gasgrid.fi. Please note that erroneous capacity bookings will be cancelled in full, and shipper is responsible of making the correct new capacity booking, if needed. Gasgrid’s customer service responds during working hours and requests that have been sent after working hours will be processed during the next working day. ",
        "line2": "For further information regarding capacity booking corrections see",
        "linkDescription": "Gas Market Code and Information Exchange Guidelines"
      }
    },
    "dialog": {
      "actionMessage": "Haluatko varmasti {{action}} tämän kapasiteetin varauksen?",
      "totalPriceMessage": "Total price: {{totalPrice}}",
      "dailyAggregatesTitle": "Total daily capacities:",
      "aggregateMessage": "{{date}}: {{value}} kWh/d",
      "hourlyAggregateMessage": "Total hourly capacity: {{value}} kWh/d"
    },
    "message": {
      "fetchError": "Error fetching capacity reservation"
    }
  },
  "capacityRightsTransfer": {
    "title": {
      "capacityRightsTransfer": "Capacity rights transfer",
      "yourCapacityRightsTransfer": "Kapasiteettisi oikeuksien siirrot"
    },
    "subtitle": {
      "unconfirmedCapacityRightsTransfers": "Vahvistamattomat kapasiteettioikeuksien siirrot",
      "processedCapacityRightTransfers": "Käsitelty kapasiteetin siirto"
    },
    "form": {
      "title": {
        "newCapacityRightsTransfer": "Uusi kapasiteettioikeuksien siirto"
      },
      "label": {
        "entryPoint": "Syöttöpaikka",
        "buyer": "Ostaja",
        "gasDeliveryDay": "Kaasun toimituspäivä",
        "capacity": "Kapasiteetti"
      }
    }
  },
  "capacityProductInstance": {
    "label": {
      "amount": "Määrä",
      "end": "Loppuu pvm",
      "price": "/kWh",
      "start": "Alkaa pvm",
      "unit": "kWh/h",
      "date": "Date",
      "startHour": "Starting",
      "endHour": "Ending"
    },
    "datePicker": {
      "day": {
        "placeholder": "Valitse kaasupäivä"
      },
      "month": {
        "placeholder": "Valitse kaasukuukausi"
      }
    }
  },
  "deliveryRelationship": {
    "title": {
      "deliveryRelationships": "Delivery relationships",
      "transferNetworkMeteringSites": "Siirrä verkon mittauspaikat",
      "biogasEntryPoints": "Biogas entry points",
      "cityGates": "City Gates",
      "created": "Created relationships",
      "received": "Received relationships",
      "networkLevel": "Network level relationships"
    },
    "new": {
      "title": "New delivery relationship",
      "submit": {
        "action": {
          "description": "Your delivery relationship request will be sent to the chosen shipper for approval."
        },
        "button": {
          "title": "Add new relationship"
        }
      }
    },
    "modal": {
      "title": "Edit delivery relationship",
      "message": {
        "success": "Delivery relationship successfully updated",
        "error": "Error updating delivery relationship"
      }
    }
  },
  "marketParty": {
    "title": {
      "marketParty": "Markkinaosapuoli",
      "marketParties": "Markkinaosapuolet"
    },
    "modal": {
      "marketPartyDeleteTitle": "Haluatko varmasti poistaa tämän markkinaosapuolen?",
      "marketPartyDeleteContent": "Tämä poistaa markkinaosapuolen tiedot sekä kaikki käyttäjät markkinaosapuolelta. Jos markkinaosapuolella on lyhytkoodi, sitä ei vapauteta uudelleen käyttöön."
    },
    "dialog": {
      "acceptActionMessage": "Haluatko varmasti hyväksyä tämän markkinaosapuolen?"
    },
    "label": {
      "typeOfMarketParty": "Roolit",
      "network": "Network",
      "notes": "Notes",
      "shipper": "Shipper",
      "trader": "Trader",
      "biogasInjector": "Biokaasun verkkoonsyöttäjä",
      "transmissionNetworkEndUser": "Siirtoverkon loppukäyttäjä",
      "retailer": "Vähittäismyyjä",
      "tso": "TSO",
      "dso": "DSO",
      "lto": "LTO",
      "authority": "Authority",
      "external": "External",
      "gasgrid": "Gasgrid",
      "baltic": "Baltic",
      "lngHamina": "LNG Hamina",
      "lngInkoo": "LNG Inkoo",
      "visibility": "Market party visibility",
      "hidden": "Hidden"
    }
  },
  "balanceContainer": {
    "title": {
      "balance": "Tase",
      "balanceHistory": "Balance history ({{start}} – {{end}})",
      "newBalanceGroup": "Create a new balance group",
      "balanceGroups": "Taseryhmät",
      "balanceGroup": "Balance Group",
      "myBalanceInfo": "My balance information",
      "members": "Members",
      "editMembership": "Edit {{shipperName}}'s balance group membership",
      "membershipStartDate": "Membership starts at",
      "membershipEndDate": "Membership ends at",
      "balanceGroupStarts": "Taseryhmän aloituspäivä",
      "groupImbalance": "Ryhmän tasepoikkeama",
      "myImbalance": "My imbalance",
      "entry": "Syöttö",
      "exit": "Otto",
      "net": "Entry / Exit net"
    },
    "button": {
      "newBalanceGroups": "Uusi taseryhmä",
      "add": "Add"
    },
    "emptyPage": {
      "description": {
        "noBalanceGroup": "Ei näytettäviä tietoja. Luo uusi taseryhmä.",
        "pendingBalanceGroup": "Ei näytettäviä tietoja. Taseryhmäsi odottaa TSO:n hyväksyntää."
      }
    },
    "modal": {
      "imbalanceParty": "imbalance responsible party",
      "description_1": "By creating a new balance group you are applying to become an <0>{{party}}</0> for the group. Your credentials and additional colleteral information will be checked and you will be informed on any needed actions on your part.",
      "description_2": "You will recieve a notification when the approval process is done."
    },
    "message": {
      "createdSuccessfully": "New balance group created successfully.",
      "errorCreating": "Error creating new balance group.",
      "updatedSuccessfully": "Taseryhmä päivitetty onnistuneesti",
      "errorUpdating": "Virhe päivitettäessä taseryhmää",
      "errorFetchMembers": "Error fetching balance group's members",
      "balanceGroupStarts": "Aloituspäivä voi olla aikaisintaan kolmen päivän päästä"
    },
    "label": {
      "self": "self",
      "addNewMembers": "Add new members :",
      "selectOrSearch": "Search and select shippers",
      "chooseDate": "Valitse päivä",
      "imbalance": "Tasepoikkeama",
      "forGasDay": "for gas day {{selectedDate}}",
      "forGasDaySoFar": "for gas day {{selectedDate}} so far",
      "acquisitions": "Syötöt",
      "biogas": "Biokaasu",
      "import": "Syöttö Imatra, BC",
      "energy": "Energia",
      "surrenders": "Otot",
      "export": "Otto",
      "totalEntry": "Syötöt yhteensä",
      "totalExit": "Otot yhteensä",
      "total": "Total",
      "domesticEntryExit": "Domestic entry / exit",
      "vtp": "Virtual Trading Point (VTP)",
      "importsExports": "Imports / exports",
      "statsChooseDate": "Choose date to be viewed",
      "previous": "<< Prev",
      "next": "Next >>",
      "estimated": "Delivery estimate"
    },
    "membership": {
      "message": {
        "addedSuccessfully": "New member added to balance group successfully.",
        "errorAdding": "Error adding new member to balance group.",
        "updatedSuccessfully": "Membership updated successfully.",
        "errorUpdating": "Error updating membership.",
        "membershipEndDate": "Päättymispäivä voi olla aikaisintaan kolmen päivän päästä"
      }
    }
  },
  "detectBrowser": {
    "modal": {
      "title": "Vaihda selain",
      "subheader": "Emme tue tätä selainta.",
      "helptext": "Varmista, että käytät yhtä tuetuista selaimista.",
      "browsers": "Selaimet"
    }
  },
  "announcements": {
    "modal": {
      "title": "New Announcement",
      "updateAnnouncementsTitle": "Update Announcement"
    },
    "label" : {
      "chooseDate": "Choose publish date",
      "updatedOn": "Updated on {{date}}",
      "headingLinkTitle": "Link titles",
      "linkTitle": "Link title",
      "headingLinkUrl": "URLs",
      "linkUrl": "URL",
      "subject": "Subject",
      "announcementText": "Announcement message",
      "status": "Status"
    },
    "status": {
      "pending": "Unpublished",
      "accepted": "Published"
    },
    "button": {
      "newAnnouncement": "New Announcement"
    },
    "notifications": {
      "errorFetching": "Error occured while fetching the list of Announcements.",
      "errorSaving": "Error occured while saving.",
      "successCreating": "Announcement created succesfully",
      "successUpdating": "Announcement updated succesfully"
    }
  },
  "users": {
    "title": {
      "newUser": "Add new user",
      "updateUser": "Update user",
      "deleteUser": "Delete user",
      "userInfo": "User Information",
      "contactPersonInfo": "Contact Person Information",
      "permissions": "Permissions",
      "read": "read",
      "write": "write",
      "confirmDelete": "Are you sure you wish to delete this user?",
      "notFound": "User not found"
    },
    "form": {
      "label": {
        "admin": "Admin",
        "invoice": "Invoice",
        "firstname": "First name",
        "lastname": "Last name",
        "phone": "Phone number",
        "email": "Email",
        "shipper": "Shipper",
        "trader": "Trader",
        "enduser": "End user",
        "producer": "Biogas injector",
        "retailer": "Retailer",
        "tso": "TSO",
        "dso": "DSO",
        "lto": "LTO"
      }
    },
    "notification": {
      "deleteSuccess": "User deleted succesfully",
      "deleteError": "Error deleting user"
    }
  },
  "nominations": {
    "title": {
      "yourNominations": "Your nominations",
      "networkNominations": "Network nominations",
      "newNomination": "New nomination",
      "renomination": "Renomination",
      "nomination": "Nomination",
      "nominationDetails": "Nomination details",
      "hourlyGasAmounts": "Hourly gas amount ({{unit}})",
      "hour": "Hour",
      "gasAmount": "NOMINT / NOMRES",
      "nominationMatchingGasAmount": "NOMINT / MATCHED / NEW MATCH",
      "nominationIntentions": "Nomination intentions",
      "confirmedNominations": "Confirmed nominations (for past 30 days)",
      "nominationsSummary": "Nominations summary",
      "nominationInformation": "Nomination information",
      "totalAmounts": "Total amounts",
      "nominationMatching": "Nomination Matching",
      "nomationMatchingConfirmation": "Are you sure you want to submit new match values?"
    },
    "button": {
      "newNomination": "New nomination",
      "sendNomination": "Send nomination",
      "sendRenomination": "Send renomination",
      "match": "Match"
    },
    "label": {
      "pointDirection": "Point — Direction",
      "date": "Gas delivery date",
      "shipperEIC": "Counterparty",
      "onBehalfOfMarketPartyId": "Nominate on behalf of",
      "imatra-entry": "Imatra — Entry",
      "balticConnector-entry": "Balticconnector — Entry",
      "balticConnector-exit": "Balticconnector — Exit",
      "lngHamina-entry": "LNG Hamina — Entry",
      "lngInkoo-entry": "LNG Inkoo — Entry",
      "vtp-entry": "VTP — Entry",
      "vtp-exit": "VTP — Exit",
      "entry": "Entry",
      "exit": "Exit",
      "chooseDate": "Choose date",
      "unit": "kWh",
      "hoverText": "Copy for all {{hoursInGasDay}} hours",
      "renominationHoverText": "Copy for all remaining hours",
      "isExternalEic": "External counterparty",
      "selectCounterParties": "Select counter parties"
    },
    "message": {
      "createdSuccessfully": "New nomination created successfully.",
      "updatedNomresesSuccessfully": "Updated nomres amounts successfully.",
      "errorCreating": "Error creating new nomination.",
      "bcAvailableCapacity": "Available capacity D-1: {{value}} {{unit}}",
      "bcAvailableCapacityUntil": "For nominations made before {{datetime}}",
      "bcInstructions": [
        "Nominations in Balticconnector have to be equal (“flat”) for each effective hour of the gas day (effective hour = hours of the day for which a nomination can be effective excluding lead time).",
        "Note that the shipper’s aggregated nominations per direction shall not exceed the technical capacity of Balticconnector."
      ]
    },
    "nomintMissing": {
      "message": "NOMINT data missing",
      "description": "No NOMINT data was found corresponding to this nomination"
    }
  },
  "relationships": {
    "title": {
      "newRelationship": "New market party relationship"
    },
    "label": {
      "types": {
        "shipper": "Retailer – Shipper",
        "shipperGroup": "Shipper group",
        "authorizeToNominate": "Authorize to nominate"
      },
      "shipper": "Shipper",
      "marketparty": "Marketparty",
      "startDate": "Start date",
      "endDate": "End date",
      "type": "Relationship Type",
      "authoriseShipperCheckbox":
        "May nominate on behalf of <strong>{{marketPartyName}}</strong> for"
    },
    "button": {
      "newRelationship": "New relationship"
    },
    "message": {
      "createdSuccessfully": "Relationship created successfully.",
      "errorCreating": "Error creating relationship.",
      "updatedSuccessfully": "Relationship updated successfully.",
      "errorUpdating": "Error updating relationship."
    }
  },
  "monitoring": {
    "cardLabel": {
      "availableCapacity": "Available capacity",
      "bookedCapacity": "Booked capacity",
      "nominations": "Nominations",
      "nomint": "NOMINT",
      "nomres": "NOMRES"
    },
    "cardInfo": {
      "noData": "No data"
    },
    "cardSubHeading": {
      "entry": "Entry",
      "exit": "Exit",
      "net": "Net"
    },
    "capacityAndNominations": {
      "title": "Capacity and nominations monitoring - {{unit}}",
      "cardContainerTitle": {
        "physicalBalanceSummary": "Physical balance summary",
        "imatra": "Imatra - entry",
        "balticConnector": "BC - entry / exit",
        "exitZone": "Exit zone - exit",
        "biogas": "Biogas - entry",
        "lng": "LNG - entry",
        "lngHamina": "LNG Hamina - entry",
        "lngInkoo": "LNG Inkoo - entry"
      },
      "cardLink": "View detailed info",
      "modal": {
        "title": "exit zone shippers",
        "imatraTitle": "Imatra shippers",
        "errorFetch": "Fetch stats error",
        "imbalance": {
          "consumption": {
            "title": "Consumption",
            "fetchError": "Error fetching consumptions",
            "updateError": "Error updating consumptions"
          },
          "changeEstimatesBy": "Change existing estimates by:"
        }
      },
      "datepicker": {
        "title": "Choose first date to be viewed",
        "valueChangedConfirmation": "Manual value is changed, do you want to save changes?"
      }
    },
    "capacitySettings": {
      "title": "Capacity settings",
      "cardContainerTitle": {
        "imatra": "Imatra"
      }
    }
  },
  "setTechnicalCapacity": {
    "modal": {
      "title": "Set technical capacity"
    }
  },
  "capacityAvailabilityForm": {
    "title": {
      "setOfferedCapacity": "Set offered capacity"
    },
    "label": {
      "dailyCapacity": "Daily capacity",
      "closedForTheDay": "closed for the day",
      "withinDayCapacity": "Within day capacity",
      "notYetOpened": "not yet opened to set"
    },
    "message": {
      "updatedSuccessfully": "Available capacity updated successfully.",
      "errorUpdating": "Error updating available capacity."
    }
  },
  "frontPage": {
    "title": "Front page",
    "box1": {
      "title": "Transmission Network"
    },
    "box2": {
      "title": "Balticconnector's capacities",
      "text": "Information regarding Balticconnector's capacities (updates hourly)"
    },
    "box3": {
      "title": "Tiedotteet (3kk ajalta)"
    },
    "box4": {
      "title": "Gas balance prices"
    }
  },
  "transmissionNetworkState": {
    "description": "The transmission network is {{imbalanceText}} ({{imbalanceTime}})",
    "short": "short",
    "long": "long",
    "nominal": "nominal",
    "unknown": "unknown",
    "title": {
      "setNetworkStatus": "Set network status"
    },
    "label": {
      "networkStatus": "Network status",
      "imbalanceMethod": "Imbalance method",
      "green": "Green",
      "red": "Red",
      "yellow": "Yellow"
    },
    "message": {
      "savedSuccessfully": "Transmission network state saved successfully.",
      "errorSaving": "Error saving transmission network state."
    }
  },
  "balanceGasPricesTable": {
    "title": {
      "buy": "Buy price",
      "sell": "Sell price"
    },
    "subTitle": {
      "buy": "{{priceType}} buy price for the ongoing gas day and final buy prices",
      "sell": "{{priceType}} sell price for the ongoing gas day and final sell prices"
    },
    "overridden": "Overridden",
    "preliminary": "Preliminary",
    "overriddenAt": "Overridden at"
  },
  "dateRangePicker": {
    "label": {
      "startDate": "Start Date",
      "endDate": "End Date"
    }
  },
  "balanceGasPriceDialog": {
    "buy": "Buy",
    "sell": "Sell",
    "buttonLabel": "Set balance gas price",
    "title": "Set balance gas price for {{date}}",
    "priceToUpdate": "Price to update",
    "newPrice": "New price (€/MWh)",
    "errorMessage": "Error saving balance gas price",
    "warning": {
      "statusNotYellow": "Network status should be set to yellow when setting balance gas prices.",
      "statusNominal": "Balance prices should not be set when the network balance is nominal.",
      "statusShort": "Sell price should be set when the network is short.",
      "statusLong": "Buy price should be set when the network is long."
    }
  },
  "meteringSites": {
    "title": {
      "gasConsumption": "Gas consumption in metering sites"
    },
    "label": {
      "noMeteringSiteFound": "No metering sites found for the selected market party"
    },
    "table": {
      "title": {
        "gasConsumptionPerMeteringSite": "Gas consumption per metering site ({{unit}})"
      },
      "information": {
        "heat": "Gross calorific value (kWh/m3n)",
        "volume": "Volume (m3n)"
      }
    }
  },
  "selectMarketParty": {
    "title": "Select market party",
    "label": {
      "marketParties": "Market parties",
      "selectOrSearch": "Search and select market party"
    }
  },
  "deliveries": {
    "title": "Toimitukset"
  },
  "biogasDeliveries": {
    "title": "Biogas deliveries"
  },
  "invoices": {
    "category": {
      "imbalance": "Balance responsible invoices",
      "shipper": "Shipper invoices",
      "taxes": "Tax invoices",
      "imbalance_corr": "Balance responsible correction invoices",
      "shipper_corr": "Shipper correction invoices",
      "taxes_corr": "Tax correction invoices",
      "unknown": "Uncategorized invoices"
    },
    "title": "Your invoices for {{startDate}} – {{endDate}}",
    "label": {
      "datePicker": "Choose month to be viewed:",
      "estimated": "Displayed values are current estimates and not invoiced yet"
    },
    "notification": {
      "noDailyValues": {
        "message": "No daily values",
        "description": "No daily values found for {{product}}"
      }
    },
    "product": {
      "DAILY_IMBAL_CHARGE_P": "Daily imbalance compensation",
      "DAILY_IMBAL_CHARGE_N": "Daily imbalance charge",
      "EXIT_Y": "Exit capacity - Yearly product",
      "EXIT_Q": "Exit capacity - Quarterly product",
      "EXIT_M": "Exit capacity - Monthly product",
      "EXIT_D": "Exit capacity - Daily product",
      "EXIT_WD": "Exit capacity - Within day product",
      "ENTRY_IMATRA_Y": "Entry capacity Imatra - Yearly product",
      "ENTRY_IMATRA_Q": "Entry capacity Imatra - Quarterly product",
      "ENTRY_IMATRA_M": "Entry capacity Imatra - Monthly product",
      "ENTRY_IMATRA_D": "Entry capacity Imatra - Daily product",
      "ENTRY_IMATRA_WD": "Entry capacity Imatra - Within day product",
      "ENTRY_BIOGAS_Y": "Entry capacity Biogas - Yearly product",
      "ENTRY_BIOGAS_Q": "Entry capacity Biogas - Quarterly product",
      "ENTRY_BIOGAS_M": "Entry capacity Biogas - Monthly product",
      "ENTRY_BIOGAS_D": "Entry capacity Biogas - Daily product",
      "ENTRY_BIOGAS_WD": "Entry capacity Biogas - Within day product",
      "ENTRY_LNG_H_Y": "Entry capacity Hamina LNG - Yearly product",
      "ENTRY_LNG_H_Q": "Entry capacity Hamina LNG - Quarterly product",
      "ENTRY_LNG_H_M": "Entry capacity Hamina LNG - Monthly product",
      "ENTRY_LNG_H_D": "Entry capacity Hamina LNG - Daily product",
      "ENTRY_LNG_H_WD": "Entry capacity Hamina LNG - Within day product",
      "ENTRY_LNG_I_Y": "Entry capacity Inkoo LNG - Yearly product",
      "ENTRY_LNG_I_Q": "Entry capacity Inkoo LNG - Quarterly product",
      "ENTRY_LNG_I_M": "Entry capacity Inkoo LNG - Monthly product",
      "ENTRY_LNG_I_D": "Entry capacity Inkoo LNG - Daily product",
      "ENTRY_LNG_I_WD": "Entry capacit Inkooy LNG - Within day product",
      "EXIT_CAP_OVERRUN": "Exit capacity overrun charge",
      "BIOGAS_CAP_OVERRUN": "Entry capacity Biogas overrun charge",
      "COMM_CHARGE_EXIT": "Commodity charge",
      "BC_RENOM_CHARGE_ENTR": "Balticconnector entry renomination charge",
      "BC_RENOM_CHARGE_EXIT": "Balticconnector exit renomination charge",
      "CO2_TAX": "CO2 tax",
      "ENERGY_TAX": "Energy tax",
      "STRAT_STOCK_PILE_FEE": "Strategic stockpile fee",
      "COMM_CHARGE_EXIT_COR": "Commodity charge Correction",
      "EXIT_CAP_OVERR_LESS": "Exit capacity overrun charge Correction Less",
      "EXIT_CAP_OVERR_MORE": "Exit capacity overrun charge Correction More",
      "BIOGAS_CAP_OVER_LESS": "Entry capacity overrun charge Biogas Correction Less",
      "BIOGAS_CAP_OVER_MORE": "Entry capacity overrun charge Biogas Correction More",
      "COMM_CHARGE_EXIT_C2": "Commodity charge Correction 2",
      "EXIT_CAP_OVER_MO_C2": "Exit capacity overrun charge Correction 2 More",
      "EXIT_CAP_OVER_LE_C2": "Exit capacity overrun charge Correction 2 Less",
      "BIO_CAP_OVER_MORE_C2": "Entry capacity overrun charge Biogas Correction 2 More",
      "BIO_CAP_OVER_LESS_C2": "Entry capacity overrun charge Biogas Correction 2 Less",
      "DAILY_IMBAL_0_N_COR": "Daily imbalance charge Correction Neutral price negative",
      "DAILY_IMBAL_0_P_COR": "Daily imbalance charge Correction Neutral price  positive",
      "DAILY_IMBAL_N_COR": "Daily imbalance charge Negative Correction",
      "DAILY_IMBAL_P_COR": "Daily imbalance charge Positive Correction",
      "DAILY_IMBAL_NEUT_C2": "Daily imbalance charge Correction 2 Neutral",
      "DAILY_IMBAL_PURC_C2": "Daily imbalance charge Correction 2 Positive",
      "DAILY_IMBAL_SELL_C2": "Daily imbalance charge Correction 2 Negative"
    }
  },
  "deposits": {
    "title": "Deposits",
    "error": "Set deposit must be a non-negative number",
    "modal": {
      "title": "Values for calculating the used deposit ({{marketParty}})",
      "capacityTotals": "Monthly totals from capacity invoices",
      "taxTotals": "Monthly totals from tax invoices",
      "boundCapacity": "Capacity (all products) for {{start}}-{{end}}: {{price}}",
      "boundCapacityEstimate": "Estimated capacity (all products) for {{start}}-{{end}}: {{price}}",
      "imbalance": "Imbalance charges for {{start}}-{{end}}: {{price}}",
      "imbalanceEstimate": "Estimated imbalance charges for {{start}}-{{end}}: {{price}}",
      "overrunEstimate": "Estimated overruns (all products) for {{start}}-{{end}}: {{price}}",
      "tax": "Taxes and fees for {{start}}-{{end}}: {{price}}"
    },
    "label": {
      "setAmount": "Set deposit",
      "updatedAt": " (updated at {{timestamp}})",
      "usedAmount": "Used deposit",
      "remainingAmount": "Remaining deposit",
      "startGasDay": "Alkaen kaasupvm",
      "endGasDay": "Päättyy kaasupvm",
      "editDeposit": "Edit deposit for {{marketParty}}"
    }
  },
  "declareGasTax": {
    "title": "Ilmoita kaasuvero",
    "modal": {
      "pg02_natural_gas": "Maakaasu",
      "pg05_biogas": "Biokaasu (ei-kestävä)",
      "pg06_biogas_r": "Biokaasu R (RED II, uusiutuva)",
      "pg07_biogas_t": "Biokaasu T (kestävä)",
      "pg08_biogas_heating": "Biokaasu lämmitys (ei-kestävä)",
      "pg09_biogas_heating_r": "Biokaasu lämmitys R (RED II, uusiutuva)",
      "pg10_biogas_heating_t": "Biokaasu lämmitys T (kestävä)",
      "pg08a_biogas_chp": "Biokaasu lämmitys & sähkö (ei-kestävä)",
      "pg09a_biogas_chp_r": "Biokaasu lämmitys & sähkö R (RED II, uusiutuva)",
      "pg10a_biogas_chp_t": "Biokaasu lämmitys & sähkö T (kestävä)",
      "pg05a_biogas_chp": "Biokaasu lämmitys & sähkö (ei-kestävä)",
      "pg06a_biogas_chp_r": "Biokaasu lämmitys & sähkö R (RED II, uusiutuva)",
      "pg07a_biogas_chp_t": "Biokaasu lämmitys & sähkö T (kestävä)",
      "pg02a_natural_gas_chp": "Maakaasu lämmitys & sähkö",
      "help": "Energia-arvot tulee syöttää ylemmässä lämpöarvossa (HHV)"
    },
    "delete": {
      "title": "Poista",
      "confirm": "Poistetaanko kaasumääräilmoitus?",
      "error": "Virhe poistettaessa ilmoitusta",
      "success": "Ilmoitus poistettu",
      "period": "Kausi",
      "meteringSite": "Mittauspaikka"
    },
    "message": {
      "createdSuccessfully": "Uusi biokaasuvero ilmoitettu onnistuneesti",
      "errorCreating": "Virhe uuden biokaasuveron ilmoittamisessa."
    },
    "table": {
      "title": "Ylempi lämpöarvo ({{unit}})",
      "actions": {
        "declare": "Ilmoita",
        "delete": "Poista"
      },
      "information": {
        "consumption": "Consumption",
        "pg02_natural_gas_chp": "NG",
        "pg05_biogas": "BG",
        "pg06_biogas_r": "R",
        "pg07_biogas_t": "T",
        "pg08_biogas_heating": "BG H",
        "pg09_biogas_heating_r": "R",
        "pg10_biogas_heating_t": "T",
        "pg08a_biogas_chp": "BG CHP",
        "pg09a_biogas_chp_r": "R",
        "pg10a_biogas_chp_t": "T",
        "pg05a_biogas_chp": "BG CHP",
        "pg06a_biogas_chp_r": "R",
        "pg07a_biogas_chp_t": "T",
        "pg02a_natural_gas_chp": "NG CHP"
      }
    }
  }
}
