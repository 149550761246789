import React, { useState, useContext, useEffect } from 'react';
import { API } from 'aws-amplify';

import {
  Button, Card, notification,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './index.less';
import { modalConfirmClose } from '../ModalWrapper';
import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import Announcement from './Announcement';
import { ANNOUNCEMENT_STATUS } from '../../constants/status';
import AnnouncementModal from './AnnouncementModal';
import { dateSort } from '../../utils/sort';

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

const Announcements = () => {
  const { t } = useTranslation();
  const [announcementState, setAnnouncementState] = useState({});
  const [edit, setEdit] = useState(false);
  const context = useContext(Context);
  const {
    isAdmin,
    announcements,
    updateAnnouncements,
    announcementsLoading,
    updateAnnouncementsLoading,
  } = context;


  const hasPublishedTimePassed = (announcement) => moment(announcement.publishedAt).isSameOrBefore(moment());
  const filterAnnouncements = (announcement) => {
    if (isAdmin()) return true;
    return (
      announcement.status === ANNOUNCEMENT_STATUS.PUBLISHED && hasPublishedTimePassed(announcement)
    );
  };

  const {
    message, subject, id, publishedAt, status,
    links = new Array(2).fill({}), // for now only two link fields are allowed
  } = announcementState;

  useEffect(() => {
    if (!announcements) {
      (async () => updateAnnouncements())();
    }
  }, []);

  const handleEditAnnouncement = (item) => {
    setAnnouncementState({
      ...item,
      publishedAt: moment(item.publishedAt),
      status: item.status || ANNOUNCEMENT_STATUS.UNPUBLISHED,
    });
    setEdit(true);
  };

  const closeModal = () => {
    setAnnouncementState({});
    setEdit(false);
  };

  const handleModalClose = () => {
    const announcement = announcements.find(
      ({ id: announcementId }) => (announcementId === announcementState.id),
    );

    if (id === 'new' && message !== '') {
      modalConfirmClose(t, closeModal);
    } else if (announcement
      && (
        announcement.message !== announcementState.message
        || announcement.subject !== announcementState.subject
        || announcement.publishedAt !== announcementState.publishedAt
      )) {
      modalConfirmClose(t, closeModal);
    } else {
      closeModal();
    }
  };

  const handleSave = async () => {
    try {
      updateAnnouncementsLoading(true);
      if (id === 'new') {
        await API.post('FINTSO', '/announcements', {
          body: {
            message,
            subject,
            publishedAt: publishedAt.format(DATETIME_FORMAT),
            links: links.filter(l => l.url),
            status,
          },
        });
        notification.success({
          message: t('announcements.notifications.successCreating'),
          description: '',
          placement: 'bottomRight',
        });
      } else {
        await API.patch('FINTSO', `/announcements/${id}`, {
          body: {
            message,
            subject,
            publishedAt: publishedAt.format(DATETIME_FORMAT),
            links: links.filter(l => l.url),
            status,
          },
        });
        notification.success({
          message: t('announcements.notifications.successUpdating'),
          description: '',
          placement: 'bottomRight',
        });
      }

      // Update the local state with new list of announcements.
      updateAnnouncements();
      closeModal();
    } catch (error) {
      notification.error({
        className: 'notification-error',
        message: t('announcements.notifications.errorSaving'),
        description: createErrorMessage(error),
      });
    } finally {
      updateAnnouncementsLoading(false);
    }
  };


  return (
    <section className="announcements">
      <Card
        bordered={false}
        title={t('frontPage.box3.title')}
      >
        <AnnouncementModal
          isEdit={edit}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
          announcement={announcementState}
          handleEditAnnouncement={handleEditAnnouncement}
          onChangeAnnouncement={(announcement) => setAnnouncementState({
            ...announcement,
            // Adding a single empty link object of announcement does't have any links in it.
            links: announcement.links && announcement.links.length === 0
              ? [{}]
              : announcement.links,
          })}
        />
        {
          isAdmin() && (
            <Row className="announcements__button-new-container">
              <Button
                className="announcements--button-new"
                data-testid="new-announcements-button"
                type="primary"
                onClick={() => handleEditAnnouncement({
                  message: '',
                  subject: '',
                  id: 'new',
                  links: [{}],
                })} // new announcement has empty message
              >
                {t('announcements.button.newAnnouncement')}
              </Button>
            </Row>
          )
        }
        <Row className="announcements__container">
          {announcements
            && announcements.length > 0
            && announcements
              // sort by latest date first
              .sort((a, b) => dateSort(b.publishedAt, a.publishedAt))
              .filter(filterAnnouncements).map(announcement => (
                <Announcement
                  {...{
                    key: announcement.id,
                    announcement,
                    handleEditAnnouncement,
                    isLoading: announcementsLoading,
                  }}
                />
              ))}
        </Row>
      </Card>
    </section>
  );
};

export default Announcements;
