const IMBALANCE_STATUS = {
  SHORT: 'short',
  NOMINAL: 'nominal',
  LONG: 'long',
  UNKNOWN: 'unknown',
};

const NETWORK_STATUSES = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
};

const IMBALANCE_METHOD = {
  AUTOMATIC: 'automatic',
  SHORT: IMBALANCE_STATUS.SHORT,
  LONG: IMBALANCE_STATUS.LONG,
};

const NETWORK_STATUS_NAMES = Object.values(NETWORK_STATUSES);

const IMBALANCE_METHOD_NAMES = Object.values(IMBALANCE_METHOD);

export {
  IMBALANCE_STATUS,
  NETWORK_STATUSES,
  NETWORK_STATUS_NAMES,
  IMBALANCE_METHOD,
  IMBALANCE_METHOD_NAMES,
};
