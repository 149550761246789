import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  notification,
  Select,
  Row,
  Col,
} from 'antd';
import {
  withRouter,
} from 'react-router-dom';
import { API, Logger } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import Context from '../../context';
import { createErrorMessage } from '../../context/globalStateHelper';
import {
  IMBALANCE_METHOD,
  IMBALANCE_METHOD_NAMES,
  NETWORK_STATUS_NAMES,
  NETWORK_STATUSES,
} from '../../constants/networkstatus';
import ModalWrapper, { modalConfirmClose } from '../ModalWrapper';

import './setNetworkStatus.less';

const logger = new Logger('components:SetNetworkStatus');

class SetNetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      status: '',
      method: '',
    };
  }

  componentDidMount = () => {
    const { status: { status, imbalanceMethod } } = this.context;

    this.setState({ status, method: imbalanceMethod });
  }

  isSaveButtonDisabled = () => {
    const { status: { status: currentStatus, imbalanceMethod: currentMethod } } = this.context;
    const { status, method } = this.state;

    return (status === currentStatus || !status) && (method === currentMethod || !method);
  }

  closeModal = () => {
    const { history } = this.props;

    return history.goBack();
  }

  handleCancel = () => {
    const { t } = this.props;

    return this.isSaveButtonDisabled()
      ? this.closeModal()
      : modalConfirmClose(t, this.closeModal);
  }

  handleSelectChange = (option) => this.setState(option);

  handleSave = async (event) => {
    event.preventDefault();

    this.setState({
      isSaving: true,
    });

    const { t } = this.props;
    const { status, method } = this.state;
    const { setStatus } = this.context;

    try {
      const response = await API.patch('FINTSO', '/admin/status', {
        body: {
          status,
          method,
        },
      });

      notification.success({
        message: t('transmissionNetworkState.message.savedSuccessfully'),
        description: '',
        placement: 'bottomRight',
      });

      setStatus(response);

      this.closeModal();
    } catch (err) {
      notification.error({
        className: 'notification-error',
        message: t('transmissionNetworkState.message.errorSaving'),
        // TODO: translatable error message
        description: createErrorMessage(err),
      });
      logger.error(createErrorMessage(err, true));
      this.setState({ isSaving: false });
    }
  }

  renderImbalanceMethod = () => {
    const { t } = this.props;
    const { method } = this.state;
    const methodField = 'imbalance-method';
    const { Option } = Select;

    return (
      <>
        <Col span={7}>
          {`${t('transmissionNetworkState.label.imbalanceMethod')}`}
        </Col>
        <Col span={17}>
          <Select
            className="set-imbalance-method__select"
            dropdownClassName="set-imbalance-method__dropdown"
            data-testid="set-imbalance-method__select"
            value={method || IMBALANCE_METHOD.AUTOMATIC}
            name={methodField}
            id={methodField}
            onChange={(newMethod) => this.handleSelectChange({ method: newMethod })}
          >
            {
              IMBALANCE_METHOD_NAMES.map(imbalanceMethod => (
                <Option className="set-imbalance-method__option" key={`${methodField}-${imbalanceMethod}`} value={imbalanceMethod}>
                  <span className={`method-icon__${imbalanceMethod}`} />
                  {t(`transmissionNetworkState.label.${imbalanceMethod}`)}
                </Option>
              ))
            }
          </Select>
        </Col>
      </>
    );
  }

  renderContent = () => {
    const { t } = this.props;
    const { status } = this.state;
    const { Option } = Select;
    const statusField = 'network-status';

    return (
      <Row>
        <Col span={7}>
          {`${t('transmissionNetworkState.label.networkStatus')}`}
        </Col>
        <Col span={17}>
          <Select
            className="set-network-status__select"
            dropdownClassName="set-network-status__dropdown"
            data-testid="set-network-status__select"
            value={status}
            name={statusField}
            id={statusField}
            onChange={(newStatus) => this.handleSelectChange({ status: newStatus })}
          >
            {
              NETWORK_STATUS_NAMES.map(networkStatus => (
                <Option className="set-network-status__option" key={`${statusField}-${networkStatus}`} value={networkStatus}>
                  <span className={`status-icon__${networkStatus}`} />
                  {t(`transmissionNetworkState.label.${networkStatus}`)}
                </Option>
              ))
            }
          </Select>
        </Col>

        {status !== NETWORK_STATUSES.GREEN && this.renderImbalanceMethod()}
      </Row>
    );
  }

  renderActionButtons = () => {
    const {
      isSaving,
    } = this.state;
    const { t } = this.props;

    return [
      <Button
        className="set-network-status__cancel-button"
        data-testid="set-network-status__cancel-button"
        key="close"
        onClick={this.handleCancel}
      >
        {t('common.button.cancel')}
      </Button>,
      <Button
        className="set-network-status__save-button"
        data-testid="set-network-status__save-button"
        key="save"
        type="primary"
        disabled={this.isSaveButtonDisabled()}
        onClick={this.handleSave}
        loading={isSaving}
      >
        {t('common.button.save')}
      </Button>,
    ];
  }

  render = () => {
    const { t } = this.props;

    return (
      <ModalWrapper
        modalClassName="set-network-status"
        title={t('transmissionNetworkState.title.setNetworkStatus')}
        handleClose={this.handleCancel}
        actionButtons={this.renderActionButtons()}
      >
        {this.renderContent()}
      </ModalWrapper>
    );
  }
}

SetNetworkStatus.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

SetNetworkStatus.displayName = 'SetNetworkStatus';
SetNetworkStatus.contextType = Context;

const SetNetworkStatusTranslated = withTranslation()(SetNetworkStatus);

export default withRouter(SetNetworkStatusTranslated);
export {
  SetNetworkStatus as PureComponent,
};
