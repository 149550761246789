/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  API,
  Auth,
  Logger,
} from 'aws-amplify';
import {
  pick,
  sortBy,
  isEmpty,
  merge,
} from 'lodash';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';

import { getDatesInRange } from '../utils/dateTimeHelpers';
import Context, { initialCurrentUserState } from './index';
import { createErrorMessage } from './globalStateHelper';
import { capacity } from './capacity';
import { monitoring } from './monitoring';
import { status } from './status';
import { prices } from './prices';
import { balanceGroups } from './balanceGroups';
import { meteringSites } from './meteringSites';
import { relationships as relationshipsState } from './relationships';
import { nominations } from './nominations';
import { users as marketpartyUsers } from './users';
import { deliveries as deliveriesCtxt } from './deliveries';
import { invoices } from './invoices';
import { deposits } from './deposits';
import { biogasTaxDeclarations } from './biogasTaxDeclarations';
import { STATUS, ROLES } from '../constants/marketParties';
import { FALLBACK_LANGUAGE } from '../i18n';
import { CAPACITY_TYPES_API } from '../constants/capacities';

// Import supported languages
moment.locale(['fi', 'en-gb']);
const log = new Logger('context:global');

// URLS
const OWNMARKETPARTIES_URL = '/users/self/marketparties';
const MARKETPARTYINDEX_URL = '/marketpartyindex';
const USERPERMISSIONS_URL = '/users/self/permissions';

const onlyMarketPartyIndexFields = [
  'id',
  'code',
  'name',
  'eic',
  'roles',
];

// When you want to get DB updates (coming through websocket)
// for something in global state, remember to add corresponding
// mapping here.
const mapTableToList = {
  MarketParty: 'marketParties',
  CapacityReservation: 'capacityReservations',
  CapacityTransfer: 'capacityTransfers',
  DeliveryRelationship: 'deliveryRelationships',
  MeteringSite: 'meteringSites',
  BalanceGroup: 'balanceGroups',
  BalanceGroupMember: 'balanceGroupMembers',
  MarketPartyUser: 'users',
  NomInt: 'nomints',
  Relationships: 'relationships',
  GasTaxDeclaration: 'taxDeclarations',
  Announcement: 'announcements',
};
const mapTableToObject = {
  CapacityReservation: 'capacityReservationsByMonth',
  TotalCapacity: 'dailyAndHourlyCapacity',
  Estimates: 'physicalBalanceSummary',
};

const partialContexts = [
  balanceGroups,
  prices,
  capacity,
  deliveriesCtxt,
  deposits,
  invoices,
  marketpartyUsers,
  meteringSites,
  monitoring,
  nominations,
  relationshipsState,
  status,
  biogasTaxDeclarations,
];

const apiEndpointEnvironment = (apiUrl) => {
  if (!apiUrl) {
    return 'unknown';
  }
  if (apiUrl.match(/\/\/api\.dev\./)) {
    return 'dev';
  }
  if (apiUrl.match(/\/\/api\.stg\./)) {
    return 'stg';
  }
  if (apiUrl.match(/\/\/api\./)) {
    return 'prod';
  }
  return 'unknown';
};

class GlobalState extends Component {
  constructor(props) {
    super(props);

    partialContexts.forEach(this.bindFunctions);

    this.state = {
      // Amplify Authenticator
      auth: {
        state: null,
        data: null,
      },
      setAuthState: this.setAuthState,

      // Current user
      currentUser: initialCurrentUserState,
      updateCurrentUser: this.updateCurrentUser,
      clearCurrentUser: this.clearCurrentUser,

      // Capacity reservations
      capacityReservations: null,
      capacityReservationsByMonth: {},
      updateCapacityReservations: this.updateCapacityReservations,

      // Capacity transfers
      capacityTransfers: null,
      updateCapacityTransfers: this.updateCapacityTransfers,

      // Capacities
      capacities: null,
      updateCapacities: this.updateCapacities,

      // Market parties
      marketParties: null,
      updateMarketParties: this.updateMarketParties,
      selectMarketParty: this.selectMarketParty,
      selectedMarketPartyId: localStorage.getItem('selectedMarketParty'),
      getSelectedMarketParty: this.getSelectedMarketParty,

      // Recent counterparty helpers
      addRecentCounterparty: this.addRecentCounterparty,
      getRecentCounterparties: this.getRecentCounterparties,

      // Market party index
      marketPartyIndex: [],
      updateMarketpartyIndex: this.updateMarketpartyIndex,

      // Delivery relationsips
      deliveryRelationships: [],
      deliveryRelationshipsLoading: false,
      updateDeliveryRelationships: this.updateDeliveryRelationships,

      // DB changes
      handleDbChange: this.handleDbChange,

      // Translation selected language
      selectedLanguage: FALLBACK_LANGUAGE,
      updateSelectedLanguage: lang => this.updateSelectedLanguage(lang),

      // Deployment ENV to global state (ENV should be setted on bitbucket pipeline settings)
      // @see: https://bitbucket.org/nordcloud/tso-frontend/admin/addon/admin/pipelines/deployment-settings
      isDevelopment: process.env.ENV === 'dev' || process.env.NODE_ENV === 'development',
      isStaging: process.env.ENV === 'stg',
      isProduction: process.env.ENV === 'prd',

      apiEndpointEnvironment: apiEndpointEnvironment(process.env.API_BASE_URL),

      // Balancegroups
      balanceGroupStats: null,
      balanceGroupStatsLoading: false,
      updateBalanceGroupStats: this.updateBalanceGroupStats,

      // Balancegroup members
      balanceGroupMembers: null,
      updateBalanceGroupMembers: this.updateBalanceGroupMembers,

      // My balance
      myBalance: null,
      myBalanceLoading: false,
      updateMyBalance: this.updateMyBalance,

      // Announcements
      announcements: null,
      announcementsLoading: false,
      updateAnnouncements: this.updateAnnouncements,
      updateAnnouncementsLoading: this.updateAnnouncementsLoading,

      // Global status
      isLoading: true,

      // Initialization
      initialize: this.initialize,

      isAdmin: this.isAdmin,
      isReadOnlyAdmin: this.isReadOnlyAdmin,
      isAdminAsMarketParty: this.isAdminAsMarketParty,
      isReadOnlyAdminAsMarketParty: this.isReadOnlyAdminAsMarketParty,

      ...partialContexts.map(this.getPartialState).reduce(merge),
    };
  }

  componentDidMount = async () => {
    const { initOnMount } = this.props;
    if (initOnMount) {
      await this.initialize();
    }
  }

  bindFunctions = (partialContext) => {
    const entries = Object.entries(partialContext);
    entries.forEach(([key, value]) => {
      if (typeof value === 'function') {
        this[key] = value.bind(this);
      }
    });
  }

  getPartialState = (partialContext) => {
    const entries = Object.entries(partialContext);
    return entries.reduce((result, [key, value]) => {
      const newValue = typeof value === 'function'
        ? this[key]
        : value;
      return {
        ...result,
        [key]: newValue,
      };
    },
    {});
  }

  /**
   * All initial calls/functionalities goes here.
   *
   */
  initialize = async () => {
    const { selectedLanguage } = this.state;

    try {
      const session = await Auth.currentSession();

      if (session !== undefined) {
        // Initial language selection
        this.updateSelectedLanguage(selectedLanguage);

        await this.updateCurrentUser();
        this.updateMarketpartyIndex();
        this.updateMarketParties();
      }

      this.setState({ isLoading: false });
    } catch (error) {
      const description = createErrorMessage(error);
      log.error('error initialising global state:', error.message, description);
      this.setState({ isLoading: false });
    }
  }

  // Amplify Authenticator state and data
  setAuthState = (state, data) => {
    this.setState({
      auth: {
        state,
        data,
      },
    });
  }

  /**
   * Clear user's data.
   */
  clearCurrentUser = () => {
    this.setState({
      currentUser: initialCurrentUserState,
      marketPartyIndex: [],
      meteringSites: null,
      deliveryRelationships: [],
      selectedMarketPartyId: null,
      balanceGroupMembers: null,
      balanceGroupStats: null,
      activeBalanceGroupId: null,
    });

    localStorage.removeItem('selectedMarketParty');
    // TODO: would need to iterate storage keys and look for prefix 'recentCounterparties#'
    // localStorage.removeItem('recentCounterparties');
    log.info('current user data cleaned');
  }

  /**
   * Update current user's data.
   */
  updateCurrentUser = async () => {
    const { t } = this.props;

    try {
      const session = await Auth.currentSession();
      const [{ items: ownMarketParties }, permissions] = await Promise.all([
        API.get('FINTSO', OWNMARKETPARTIES_URL),
        API.get('FINTSO', USERPERMISSIONS_URL),
      ]);
      const userGroups = session.idToken.payload['cognito:groups'] || [];
      const inAdminGroup = userGroups.includes('admin');
      const inReadOnlyAdminGroup = userGroups.includes('adminReadOnly');

      const currentUser = {
        ...session.idToken.payload,
        inAdminGroup,
        inReadOnlyAdminGroup,
        ownMarketParties: ownMarketParties || [],
        permissions: permissions || {},
      };
      const { selectedMarketPartyId } = this.state;

      if (selectedMarketPartyId === undefined || selectedMarketPartyId === null) {
        const defaultMarketParty = ownMarketParties[0] || {
          id: 'user-has-no-market-party',
        };
        const selectedId = (inAdminGroup || inReadOnlyAdminGroup) ? 'admin' : defaultMarketParty.id;

        this.selectMarketParty(
          selectedId,
          false,
        );
      }

      this.setState({ currentUser });
      log.info('current user updated with data:', currentUser);
    } catch (error) {
      this.setState({
        currentUser: initialCurrentUserState,
      });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorUpdatingCurrentUser'),
        description,
      });
      log.error('error updating current user:', error.message, description);
    }
  }

  /**
   * Fetch and update market party index data.
   */
  updateMarketpartyIndex = async () => {
    const { t } = this.props;

    try {
      const { items: marketPartyIndex } = await API.get('FINTSO', MARKETPARTYINDEX_URL);
      this.setState({
        marketPartyIndex: sortBy(marketPartyIndex, ['name']),
      });
      log.info('marketPartyIndex updated with data:', marketPartyIndex);
    } catch (error) {
      this.setState({ marketPartyIndex: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingMarketPartyIndex'),
        description,
      });
      log.error('error fetching market party index:', error.message, description);
    }
  }

  updateAnnouncementsLoading = (isLoading) => this.setState({ announcementsLoading: isLoading });

  /**
   * Fetch and update announcements data.
   */
  updateAnnouncements = async (start, end) => {
    // start and end date should be in YYYY-MM-DD format
    const { t } = this.props;
    this.setState({ announcementsLoading: true });
    try {
      const ANNOUNCEMENTS_URL = start && end
        ? `/announcements?start=${start}&end=${end}`
        : '/announcements';
      const { items } = await API.get('FINTSO', ANNOUNCEMENTS_URL);
      this.setState({ announcements: items });
    } catch (e) {
      this.setState({ announcements: [] });
      notification.error({
        className: 'notification-error',
        message: t('announcements.notifications.errorFetching'),
        description: createErrorMessage(e),
      });
    } finally {
      this.setState({ announcementsLoading: false });
    }
  };

  /**
   * Fetch and update market parties.
   */
  updateMarketParties = async () => {
    const { t } = this.props;

    try {
      const { isAdmin, isReadOnlyAdmin } = this.state;
      const marketPartiesUrl = isAdmin() || isReadOnlyAdmin()
        ? '/admin/marketparties'
        : '/users/self/marketparties';

      const { items: marketParties } = await API.get('FINTSO', marketPartiesUrl);
      this.setState({ marketParties: sortBy(marketParties, ['name']) });
      log.info('market parties updated with data:', marketParties);
    } catch (error) {
      this.setState({ marketParties: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingMarketParties'),
        description,
      });
      log.error('error fetching market parties:', error.message, description);
    }
  }

  /**
   * Fetch current/selected market party object.
   *
   * @returns {Object} - Market party object.
   */
  getSelectedMarketParty = () => {
    const {
      isAdmin,
      isReadOnlyAdmin,
      marketParties,
      selectedMarketPartyId,
      marketPartyIndex,
      isAdminAsMarketParty,
      isReadOnlyAdminAsMarketParty,
    } = this.state;

    if (isAdminAsMarketParty() || isReadOnlyAdminAsMarketParty()) {
      return marketPartyIndex.find(marketParty => marketParty.id === selectedMarketPartyId);
    }

    return (selectedMarketPartyId !== undefined
    && selectedMarketPartyId !== null
    && !isAdmin()
    && !isReadOnlyAdmin())
    && (marketParties && marketParties.length > 0)
      ? marketParties.find(marketParty => marketParty.id === selectedMarketPartyId)
      : {
        id: undefined,
        name: undefined,
      };
  }

  /**
   * Fetch and update delivery relationships.
   */
  updateDeliveryRelationships = async () => {
    const { t } = this.props;
    try {
      this.setState({ deliveryRelationshipsLoading: true });
      const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
      const relationshipsUrl = isAdmin() || isReadOnlyAdmin()
        ? '/admin/deliveryrelationships'
        : `/marketparties/${selectedMarketPartyId}/deliveryrelationships`;
      const { items: deliveryRelationships } = await API.get('FINTSO', relationshipsUrl);
      this.setState({ deliveryRelationships });
      log.info('delivery relationships updated with data:', deliveryRelationships);
    } catch (error) {
      this.setState({ deliveryRelationships: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingDeliveryRelationships'),
        description,
      });
      log.error('error fetching delivery relationships:', error.message, description);
    } finally {
      this.setState({ deliveryRelationshipsLoading: false });
    }
  }

  /**
   * Fetch and update capacity reservations.
   */
  updateCapacityReservations = async () => {
    const { t } = this.props;

    try {
      const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
      const reservationsUrl = isAdmin() || isReadOnlyAdmin()
        ? '/admin/capacitybookings'
        : `/marketparties/${selectedMarketPartyId}/capacitybookings`;
      const { items: capacityReservations } = await API.get('FINTSO', reservationsUrl);

      this.setState({ capacityReservations });

      const capacityReservationsByCreationMonth = capacityReservations
        .reduce((accum, reservation) => {
          const monthOfCreation = moment(reservation.createdAt).format('YYYY-MM');
          return {
            ...accum,
            [monthOfCreation]: [...(accum[monthOfCreation] || []), reservation],
          };
        }, {});

      this.setState({
        capacityReservationsByMonth: capacityReservationsByCreationMonth,
      });
    } catch (error) {
      this.setState({ capacityReservations: [], capacityReservationsByMonth: {} });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingCapacityReservations'),
        description,
      });
      log.error('error fetching capacity reservations:', error.message, description);
    }
  }

  /**
   * Fetch and update capacity transfers.
   */
  updateCapacityTransfers = async () => {
    const { t } = this.props;

    try {
      const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;
      const transfersUrl = isAdmin() || isReadOnlyAdmin()
        ? '/admin/capacitytransfers'
        : `/marketparties/${selectedMarketPartyId}/capacitytransfers`;
      const { items: capacityTransfers } = await API.get('FINTSO', transfersUrl);

      this.setState({ capacityTransfers });
    } catch (error) {
      this.setState({ capacityTransfers: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingCapacityTransfers'),
        description,
      });
      log.error('error fetching capacity transfers:', error.message, description);
    }
  }

  updateCapacities = async (
    point = CAPACITY_TYPES_API.EXITZONE, startDate = undefined, endDate = undefined) => {
    const { t } = this.props;

    try {
      const { isAdmin, isReadOnlyAdmin, selectedMarketPartyId } = this.state;

      if (isAdmin() || isReadOnlyAdmin()) {
        this.setState({ capacities: [] });
        return;
      }

      const now = moment();
      const start = startDate || now.clone().subtract(3, 'days');
      const end = endDate || now.clone().add(3, 'day');

      const capacitiesUrl = `/marketparties/${selectedMarketPartyId}/capacities`;
      const options = {
        queryStringParameters: {
          point,
          start,
          end,
        },
      };
      const { items: capacities } = await API.get('FINTSO', capacitiesUrl, options);

      this.setState({ capacities });
    } catch (error) {
      this.setState({ capacities: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingCapacities'),
        description,
      });
      log.error('error fetching capacities:', error.message, description);
    }
  }

  validateMarketPartyForIndex = marketParty => (
    marketParty.status === STATUS.ACCEPTED
    && Array.isArray(marketParty.roles)
    && [ROLES.SHIPPER, ROLES.TRADER, ROLES.RETAILER, ROLES.ENDUSER, ROLES.DSO]
      .some(role => marketParty.roles.includes(role))
  )

  updateItemInArray = (item, list) => list.map(listItem => (
    listItem.id === item.id
      ? item
      : listItem
  ))

  removeItemFromArray = (item, list) => list.filter(listItem => listItem.id !== item.id)

  addItemToList = (item, listName) => {
    this.setState(prevState => ({
      [listName]: [
        ...(prevState[listName] ? prevState[listName] : []),
        item,
      ],
    }));
    log.info(`item added to ${listName}:`, item);
  }

  updateItemInList = (item, listName) => {
    this.setState(prevState => ({
      [listName]: this.updateItemInArray(
        item,
        prevState[listName],
      ),
    }));
    log.info(`item updated in ${listName}:`, item);
  }

  removeItemFromList = (item, listName) => {
    this.setState(prevState => ({
      [listName]: this.removeItemFromArray(
        item,
        prevState[listName],
      ),
    }));
    log.info(`item removed from ${listName}:`, item);
  }

  updateItemInObject = (item, key, objectName) => {
    this.setState(state => ({
      [objectName]: {
        ...state[objectName],
        [key]: item,
      },
    }));
    log.info(`item updated in ${objectName}.${key}:`, item);
  }

  handleDbChange = (type, action, item) => {
    const listToUpdate = mapTableToList[type];
    const objectToUpdate = mapTableToObject[type];
    // eslint-disable-next-line react/destructuring-assignment
    const isNotArray = !listToUpdate || !Array.isArray(this.state[listToUpdate]);
    // eslint-disable-next-line react/destructuring-assignment
    const isNotObject = !objectToUpdate || this.state[objectToUpdate].constructor !== Object;
    if (isNotArray && isNotObject) {
      log.warn(`Unknown table mapping: "${type}". Item not updated:\n`, item);
      return;
    }

    switch (action) {
      case 'create': {
        if (type === 'MarketPartyUser') {
          const fromWebSocket = !!item.userId; // only websocket items have the userId property
          if (fromWebSocket) {
            // A created user's basic info - name, email etc. are not stored in the database
            // and thus aren't contained in the websocket event either. Currently we have no
            // way of getting a single user's information, so a full users update is needed.
            const { updateUsers } = this.state;
            updateUsers();
            break;
          }
        }

        if (type === 'MarketParty' && this.validateMarketPartyForIndex(item)) {
          // Check market party index as well
          const { marketPartyIndex } = this.state;
          if (marketPartyIndex.find(listItem => listItem.id === item.id)) {
            this.updateItemInList(pick(item, onlyMarketPartyIndexFields), 'marketPartyIndex');
          } else {
            this.addItemToList(pick(item, onlyMarketPartyIndexFields), 'marketPartyIndex');
          }
        }

        if (type === 'NomInt') {
          // Counter party nomint amounts are calculated in nomination endpoints dynamically.
          // Prevent DB update from overriding these values.
          const { nomints } = this.state;
          const nomint = nomints.find(({ id }) => id === item.id);
          if (nomint) break;
        }

        if (type === 'CapacityReservation') {
          const key = moment(item.createdAt).format('YYYY-MM');

          // eslint-disable-next-line react/destructuring-assignment
          if (this.state[objectToUpdate][key]?.find(listItem => listItem.id === item.id)) {
            this.setState(prevState => ({
              [objectToUpdate]: {
                ...prevState[objectToUpdate],
                [key]: this.updateItemInArray(item, prevState[objectToUpdate][key]),
              },
            }));
          } else {
            this.setState(prevState => ({
              [objectToUpdate]: {
                ...prevState[objectToUpdate],
                [key]: [...(prevState[objectToUpdate][key] || []), item],
              },
            }));
          }
        }

        // state list items can be null
        if ((this.state[listToUpdate] ?? []).find(listItem => listItem.id === item.id)) { // eslint-disable-line react/destructuring-assignment
          this.updateItemInList(item, listToUpdate);
          break;
        }
        this.addItemToList(item, listToUpdate);
        break;
      }

      case 'update': {
        if (type === 'MarketPartyUser') {
          const { selectedMarketPartyId, users = [] } = this.state;
          const { userId, permissions: newPermissions } = item;
          const updatedUser = users.find(({ id }) => id === userId);

          if (updatedUser) {
            const permissions = {
              ...updatedUser.permissions,
              [selectedMarketPartyId]: newPermissions,
            };
            this.updateItemInList({ ...updatedUser, permissions }, listToUpdate);
          } else {
            // If a user was not found, it means that this user was previously soft deleted from
            // this market party. Re-adding the user actually invokes the 'update' event from the
            // DynamoDB stream, so this case must be handled similarly to the 'create' case.
            const { updateUsers } = this.state;
            updateUsers();
          }
          break;
        }

        if (type === 'TotalCapacity') {
          this.updateItemInObject(item, item.date, objectToUpdate);
          break;
        }

        if (type === 'Estimates') {
          const [[gasDay, values]] = Object.entries(item);
          this.updateItemInObject(values, gasDay, objectToUpdate);
          break;
        }

        if (type === 'MarketParty' && this.validateMarketPartyForIndex(item)) {
          // Check market party index as well
          const { marketPartyIndex } = this.state;
          if (marketPartyIndex.find(listItem => listItem.id === item.id)) {
            this.updateItemInList(pick(item, onlyMarketPartyIndexFields), 'marketPartyIndex');
          }
        }

        if (type === 'NomInt') {
          // Counter party nomint amounts are calculated in nomination endpoints dynamically.
          // Prevent DB update from overriding these values.
          const { nomints } = this.state;
          const nomint = nomints.find(({ id }) => id === item.id);
          const updateItem = nomint
            ? { ...item, counterPartyAmounts: nomint.counterPartyAmounts }
            : item;
          this.updateItemInList(updateItem, listToUpdate);
          break;
        }

        if (type === 'CapacityReservation') {
          const key = moment(item.createdAt).format('YYYY-MM');
          this.setState(prevState => ({
            [objectToUpdate]: {
              ...prevState[objectToUpdate],
              [key]: this.updateItemInArray(item, prevState[objectToUpdate][key] || []),
            },
          }));
          if (!isNotArray) {
            this.updateItemInList(item, listToUpdate);
          }
          break;
        }

        this.updateItemInList(item, listToUpdate);
        break;
      }

      case 'delete': {
        if (type === 'MarketPartyUser') {
          const { userId, marketPartyId } = item;
          const { users } = this.state;
          const deletedUser = users.find(({ id }) => id === userId);
          if (deletedUser) {
            delete deletedUser.permissions[marketPartyId];
            if (Object.keys(deletedUser.permissions).length) {
              // As a TSO admin, a user may contain permissions for multiple market parties.
              // If this is the case, don't remove the entire item, but just replace its
              // permissions property.
              this.updateItemInList(deletedUser, listToUpdate);
            } else {
              // Only remove the item if it has no permissions left for any market party. This
              // occurs when a TSO admin deletes the user from their last market party OR
              // a market party admin deletes the user from their market party.
              this.removeItemFromList(deletedUser, listToUpdate);
            }
          } break;
        }

        if (type === 'MarketParty') {
          // Check market party index as well
          const { marketPartyIndex } = this.state;
          if (marketPartyIndex.find(listItem => listItem.id === item.id)) {
            this.removeItemFromList(item, 'marketPartyIndex');
          }
        }

        this.removeItemFromList(item, listToUpdate);
        break;
      }

      default: {
        log.warn('Unhandled db change type:', action);
      }
    }
  }

  updateSelectedLanguage = (selectedLanguage) => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    moment.updateLocale(selectedLanguage, {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
      week: {
        dow: 1,
      },
    });
    this.setState({ selectedLanguage });
  }

  /**
   * Set current/selected market party's id.
   *
   * @param {String} selectedMarketPartyId - Market party identifier (can be `admin` string).
   *                                        `admin` - is global admin role for user!
   * @param {Boolean} runInit - Flag to run `initialize` function, by default is `true`.
   */
  selectMarketParty = (selectedMarketPartyId, runInit = true) => {
    localStorage.setItem('selectedMarketParty', selectedMarketPartyId);
    this.setState({
      isLoading: true,
      selectedMarketPartyId,
      invoices: {},
    });
    this.resetBalanceGroupData();
    if (runInit) this.initialize();
  }

  getRecentCounterparties = () => {
    const { selectedMarketPartyId } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    return JSON.parse(localStorage.getItem(`recentCounterparties#${selectedMarketPartyId}`) || '[]');
  }

  addRecentCounterparty = (marketPartyId) => {
    const recentCounterparties = this.getRecentCounterparties();
    if (!marketPartyId) {
      // log.info('No counterparty to add to recent');
      return recentCounterparties;
    }
    if (recentCounterparties.includes(marketPartyId)) {
      // log.info('Counterparty already exists in recent');
      return recentCounterparties;
    }
    const { selectedMarketPartyId } = this.state;
    // add to the begining and limit to maximum of 5 recent
    recentCounterparties.splice(0, 0, marketPartyId);
    const updatedRecentCounterparties = recentCounterparties.slice(0, 5);
    localStorage.setItem(`recentCounterparties#${selectedMarketPartyId}`, JSON.stringify(updatedRecentCounterparties));
    // log.info('Added recent counterparty: ', marketPartyId, updatedRecentCounterparties);
    return updatedRecentCounterparties;
  }

  resetBalanceGroupData = () => {
    this.setState({
      activeBalanceGroupId: null,
      balanceGroups: null,
      balanceGroupMembers: null,
      balanceGroupStats: null,
      myBalance: null,
    });
  }

  /**
   * Fetch/update balance group stats
   */
  updateBalanceGroupStats = async (selectedDate, selectedBalanceGroupId) => {
    const { t } = this.props;

    try {
      this.setState({ balanceGroupStatsLoading: true });
      const {
        isAdmin,
        isReadOnlyAdmin,
        selectedMarketPartyId,
        activeBalanceGroupId,
        balanceGroupStats: oldBalanceGroupStats,
      } = this.state;

      if (activeBalanceGroupId === null) await this.updateActiveBalanceGroupId();
      let { activeBalanceGroupId: balanceGroupId } = this.state;
      balanceGroupId = selectedBalanceGroupId || balanceGroupId;

      const { balanceGroups: groups } = this.state;
      const isOwner = groups.find(
        group => group.id === balanceGroupId && group.marketPartyId === selectedMarketPartyId,
      );

      if (!isAdmin() && !isReadOnlyAdmin() && balanceGroupId && selectedDate && isOwner) {
        const balanceGroupStatsUrl = ''.concat(
          `/marketparties/${selectedMarketPartyId}`,
          `/balancegroups/${balanceGroupId}/stats`,
        );
        const newBalanceGroupStats = await API.get('FINTSO', balanceGroupStatsUrl, {
          queryStringParameters: {
            start: selectedDate,
            end: selectedDate,
          },
        });
        const balanceGroupStats = {
          ...oldBalanceGroupStats,
          ...newBalanceGroupStats,
        };
        this.setState({ balanceGroupStats });
        log.info('balance group stats updated with data:', balanceGroupStats);
      } else {
        this.setState({ balanceGroupStats: null });
      }
    } catch (error) {
      this.setState({ balanceGroupStats: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingBalanceGroupStats'),
        description,
      });
      log.error('error fetching balance group stats:', error.message, description);
    } finally {
      this.setState({ balanceGroupStatsLoading: false });
    }
  }

  updateMyBalance = async (selectedDate) => {
    const { t } = this.props;

    try {
      this.setState({ myBalanceLoading: true });
      const {
        isAdmin,
        isReadOnlyAdmin,
        selectedMarketPartyId,
        activeBalanceGroupId,
        balanceGroupMembers,
        myBalance: oldBalance,
      } = this.state;
      let myBalance = {};

      // Wait for balance group information before fetching stats
      if (activeBalanceGroupId === null) await this.updateActiveBalanceGroupId();
      if (balanceGroupMembers === null) await this.updateBalanceGroupMembers();

      let { activeBalanceGroupId: balanceGroupId } = this.state;
      const { balanceGroupMembers: members } = this.state;

      // Note for code debt:
      // Slightly confusing lookup, probably could replace with the single lookup?
      let selectedMembership;
      if (!balanceGroupId) {
        selectedMembership = members.find(member => (
          member.marketPartyId === selectedMarketPartyId
          && member.status === STATUS.ACCEPTED
          && getDatesInRange(member.start, member.end || selectedDate).includes(selectedDate)
        )) || {};
        if (selectedMembership) {
          // eslint-disable-next-line prefer-destructuring
          balanceGroupId = selectedMembership.balanceGroupId;
        }
      } else {
        // There's active balance group, but is it really active?
        selectedMembership = members.find(member => (
          member.marketPartyId === selectedMarketPartyId
          && member.status === STATUS.ACCEPTED
          // && member.balanceGroupId === balanceGroupId
          && getDatesInRange(member.start, member.end || selectedDate).includes(selectedDate)
        ));
        if (selectedMembership) {
          // eslint-disable-next-line prefer-destructuring
          balanceGroupId = selectedMembership.balanceGroupId;
        }
      }

      if (
        !isAdmin()
        && !isReadOnlyAdmin()
        && balanceGroupId
        && selectedDate
        && selectedMembership) {
        const myBalanceStatsUrl = ''.concat(
          `/marketparties/${selectedMarketPartyId}`,
          `/balancegroups/${balanceGroupId}`,
          `/members/${selectedMembership.id}/stats`,
        );

        myBalance = await API.get('FINTSO', myBalanceStatsUrl, {
          queryStringParameters: {
            start: selectedDate,
            end: selectedDate,
          },
        });
      }

      const myBalanceStats = {
        ...oldBalance,
        ...myBalance,
      };

      this.setState({ myBalance: myBalanceStats });
      log.info('my balance stats updated with data:', myBalanceStats);
    } catch (error) {
      this.setState({ myBalance: {} });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingMyBalance'),
        description,
      });
      log.error('error fetching my balance stats:', error.message, description);
    } finally {
      this.setState({ myBalanceLoading: false });
    }
  }

  /**
   * Fetch or update balance group memberships
   */
  updateBalanceGroupMembers = async (marketPartyChanged) => {
    const { t } = this.props;

    try {
      const {
        isAdmin,
        isReadOnlyAdmin,
        selectedMarketPartyId,
        balanceGroups: oldBalanceGroups,
      } = this.state;

      if (marketPartyChanged || oldBalanceGroups === null) {
        await this.updateBalanceGroups();
      }

      const { balanceGroups: groups } = this.state;

      if (!isAdmin() && !isReadOnlyAdmin() && selectedMarketPartyId !== 'admin' && groups && groups.length > 0) {
        const membersApiCalls = groups.map((group) => {
          const membershipUrl = `/marketparties/${selectedMarketPartyId}/balancegroups/${group.id}/members`;
          return API.get('FINTSO', membershipUrl);
        });

        const members = await Promise.all(membersApiCalls);
        const balanceGroupMembers = [].concat(
          ...members.map(member => Object.values(member.items)),
        );

        this.setState({ balanceGroupMembers });
        log.info('balance group members updated with data:', balanceGroupMembers);
      } else {
        this.setState({ balanceGroupMembers: [] });
      }
    } catch (error) {
      this.setState({ balanceGroupMembers: [] });
      const description = createErrorMessage(error);
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorFetchingBalanceGroupMembers'),
        description,
      });
      log.error('error fetching balance group members:', error.message, description);
    }
  }

  isAdmin = (checkOnlyAdminGroup = false) => {
    const {
      currentUser: {
        inAdminGroup,
      },
      selectedMarketPartyId,
    } = this.state;
    return inAdminGroup && (checkOnlyAdminGroup || selectedMarketPartyId === 'admin');
  }

  isReadOnlyAdmin = (checkOnlyAdminGroup = false) => {
    const {
      currentUser: {
        inReadOnlyAdminGroup,
      },
      selectedMarketPartyId,
    } = this.state;
    return (inReadOnlyAdminGroup && (checkOnlyAdminGroup || selectedMarketPartyId === 'admin')) || this.isAdmin(checkOnlyAdminGroup);
  }

  isAdminAsMarketParty = () => {
    const {
      currentUser: {
        ownMarketParties = [],
        inAdminGroup,
      },
      selectedMarketPartyId,
    } = this.state;
    const selectedOwnMarketParty = ownMarketParties.find(
      marketParty => marketParty.id === selectedMarketPartyId,
    );

    return inAdminGroup && selectedMarketPartyId !== 'admin' && isEmpty(selectedOwnMarketParty);
  }

  isReadOnlyAdminAsMarketParty = () => {
    const {
      currentUser: {
        ownMarketParties,
        inReadOnlyAdminGroup,
      },
      selectedMarketPartyId,
    } = this.state;
    const selectedOwnMarketParty = ownMarketParties.find(
      marketParty => marketParty.id === selectedMarketPartyId,
    );

    return this.isAdminAsMarketParty() || (inReadOnlyAdminGroup && selectedMarketPartyId !== 'admin' && isEmpty(selectedOwnMarketParty));
  }

  render = () => {
    const { children } = this.props;

    return (
      <Context.Provider value={this.state}>
        { children }
      </Context.Provider>
    );
  }
}

GlobalState.displayName = 'GlobalState';
GlobalState.propTypes = {
  children: PropTypes.shape({}),
  initOnMount: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
GlobalState.defaultProps = {
  children: {},
  initOnMount: false,
};

export default withTranslation()(GlobalState);
export {
  onlyMarketPartyIndexFields,
  GlobalState as PureComponent,
};
